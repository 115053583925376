import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useAuthStore from "store/useAuthStore";
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { fetchMasterAccess } from "api/masteraccessapi";

const LoginFormAccessToken = ({ togglePage }) => {
  const { login } = useAuthStore();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // New state to track loading

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = await fetchMasterAccess(password);
    if (data) {
      const dataToPass = { code: password };
      navigate("/master-access-dashboard", { state: dataToPass });
    } else {
      setMessage("Invalid access token");
    }
  };

  return (
    <Card className="h-full p-4">
      <CardHeader className="pb-14">
        <CardTitle className="text-optonyprimary">
          Pro Forma User Access Login
        </CardTitle>
        <CardDescription>
          Enter your access token below to login to your account
        </CardDescription>
        <p className="text-sm text-red-400">{message}</p>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleLogin}>
          <div className="grid w-full items-center gap-4">
            <div className="relative flex flex-col space-y-1.5">
              <Input
                placeholder="Access Token"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-[6px] text-gray-600 hover:text-black"
              >
                {showPassword ? <EyeOpenIcon /> : <EyeNoneIcon />}
              </button>
            </div>
            <div className="flex flex-col space-y-1.5">
              <Button type="submit" disabled={loading}>
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </div>
        </form>
        <p className="text-optonyprimary hover:text-optonyprimaryhover text-sm mt-2">
          <button className="underline" onClick={togglePage}>
            Have a user account?
          </button>
        </p>
      </CardContent>
    </Card>
  );
};

export default LoginFormAccessToken;
