import useAuthStore from "../store/useAuthStore";

export const fetchMasterAccess = async (access_code) => {
    try {

      if (!access_code) return;
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/master-access/${access_code}`,
        { headers: { } }
      );
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching city info:", error);
    }
  };
  
export const fetchMasterAccessData = async (username) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/master-access/fetchUser/${username.toLowerCase()}`,
        { headers: {
          "Content-Type": "application/json",
          Authorization: useAuthStore.getState().getToken(),
        }, }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching city info:", error);
    }


}

