import React, { useState, useEffect } from "react";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";
import { fetchMasterAccess } from "api/masteraccessapi";
import { AspectRatio } from "components/ui/aspect-ratio";
import ToggleNav from "components/ToggleNav";
import { useNavigate, useLocation } from "react-router-dom";

const MasterAccessDashboard = () => {
  const [accessCode, setAccessCode] = useState("");
  const [cityAccess, setCityAccess] = useState(null);
  const [clickedLinks, setClickedLinks] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const { code } = location.state || {};

  useEffect(() => {
    if (!code) {
      navigate("/login"); // Redirect to /login if code is missing
      return;
    }

    const fetchData = async () => {
      const data = await fetchMasterAccess(code);
      setCityAccess(data);
    };

    fetchData(); // Call the async function
  }, [code, accessCode, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = await fetchMasterAccess(accessCode);
    setCityAccess(data);
    setClickedLinks({}); // Reset clicked links when new data is fetched
  };

  const handleLinkClick = async (index, link) => {
    window.open(link, "_blank");
    setClickedLinks((prev) => ({
      ...prev,
      [index]: true, // Mark this link as clicked
    }));
    const data = await fetchMasterAccess(code);
    console.log(data)
    setCityAccess(data);
    setClickedLinks({});
  };

  return (
    <div className="w-full h-screen p-4">
      <div className="flex justify-between gap-2 mb-1">
        <ToggleNav />
      </div>
      <div className="w-full relative">
        <AspectRatio className="" ratio={10 / 3}>
          <img
            src="/ProFormaHeader.png"
            alt="Image"
            className="h-full w-full rounded-t-3xl object-cover"
          />
        </AspectRatio>
        <div className="w-full h-16 rounded-b-3xl bg-optonyprimary flex items-center px-2">
          <p className="text-white text-lg ml-4">
            OPTONY FLEET ELECTRIFICATION PRO FORMA TOOL
          </p>
        </div>
      </div>
      <div className="bg-gray2 w-full rounded-3xl p-6 mt-4">
        <div className="border bg-gray-50 w-full font-['Inter'] rounded-2xl flex flex-col gap-2 p-6 shadow-md">
          <h2 className="text-2xl text-gray-800">
            Welcome to the Fleet Electrification Pro Forma!
          </h2>
          <p className="text-gray-700 text-sm leading-relaxed">
            You may access the Fleet Electrification Pro Forma tools assigned to
            your credentials. Select any Fleet Electrification Pro Forma below
            to view or edit the account.
          </p>

          <h3 className="text-md text-gray-800">Overview</h3>
          <p className="text-gray-700 text-sm leading-relaxed">
            The Fleet Electrification Pro Forma consists of a summary of the
            costs and benefits associated with fleet electrification and
            charging infrastructure.
          </p>

          <h3 className="text-md text-gray-800">Purpose</h3>
          <p className="text-gray-700 text-sm leading-relaxed">
            This tool may be used for years to come when making decisions
            related to fleet electrification, including evaluating funding
            opportunities, vehicle replacement options, and EV charging options.
            All values provided are estimates and subject to change.
          </p>
        </div>
        <div className="w-full bg-gray-50 shadow-md rounded-2xl border mt-4 p-6">
          <p className="text-2xl font-['Inter']">Your Pro Forma Access</p>
          {cityAccess && (
            <div className="mt-2 w-[350px] border bg-white rounded-lg flex flex-col p-2 items-center">
            {cityAccess.map((city, index) => (
              <div
                key={index}
                className={`flex justify-between p-2 items-center w-full ${
                  index !== 0 ? 'border-t border-gray-200' : ''
                }`} // Adds a top border for all items except the first
              >
                <span className="font-medium text-gray-800">{city.username}</span>
                <Button
                  onClick={() => handleLinkClick(index, city.link)}
                  className="ml-2 px-4 py-2 text-white font-medium rounded-md transition-colors"
                  disabled={clickedLinks[index]}
                >
                  Access
                </Button>
              </div>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MasterAccessDashboard;
