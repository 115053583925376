/**
 * @fileoverview Navbar component, contains styles for both being open or closed
 */

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "./ui/button";
import { useNavigate } from "react-router-dom";
import {
  Pencil2Icon,
  CalendarIcon,
  BarChartIcon,
  DesktopIcon,
  ExitIcon,
} from "@radix-ui/react-icons";
import useAuthStore from "../store/useAuthStore";
import useNavState from "../store/useNavState";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import useProForma from "store/useProForma";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useMediaQuery } from "react-responsive";
import { fetchMasterAccessData } from "api/masteraccessapi";

const Navbar = ({ showLinks = true }) => {
  // variable declarations
  const { logout } = useAuthStore();
  const { isOpen, toggleNav, setNav } = useNavState();
  const { cityInfo } = useProForma();
  const [username, setUsername] = useState("");
  const { user } = useAuthStore();
  const [parentData, setParentData] = useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  /**
   * @function useEffect - Gets the username based on the user's email
   */
  useEffect(() => {
    if (!user) {
      return;
    }
    setUsername(user.email.split("@")[0]);
  }, [user]);

  useEffect(() => {
    if (!username) {
      return;
    }
    const fetchData = async () => {
      const data = await fetchMasterAccessData(username);
      if (data) {
        setParentData(data);
      }
      console.log(data);
    };
    fetchData();
  }, [username]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  /**
   * @function useEffect - Closes the navigation bar if the screen size is tablet or mobile
   */
  useEffect(() => {
    if (isTabletOrMobile) {
      setNav(false);
    }
  }, [isTabletOrMobile]);

  return (
    <>
      {isOpen ? (
        <div className="h-screen w-[250px] fixed bg-background_gray">
          <div className="flex items-center justify-between h-8 px-5 mt-4">
            <img
              src="/Optony_Logo_Name_Black 1.svg"
              alt="Logo"
              className="h-8"
            />
          </div>
          <div className="w-full pl-[53px] pt-1 text-[#8a8d89] text-base font-normal font-['Roboto'] leading-tight">
            Pro Forma
          </div>
          <nav className="p-5 mt-4">
            {showLinks && (
              <ul className="">
                <NavLink to="/">
                  {({ isActive }) => (
                    <li
                      className={`flex items-center rounded-xl py-3 text-sm font-medium mb-2 px-4 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <DesktopIcon className="mr-2" width="20" height="20" />
                      Dashboard
                    </li>
                  )}
                </NavLink>
                <NavLink to="/yearoveryear">
                  {({ isActive }) => (
                    <li
                      className={`flex items-center rounded-xl py-3 text-sm font-medium mb-2 px-4 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <BarChartIcon className="mr-2" width="18" height="18" />
                      Trends
                    </li>
                  )}
                </NavLink>
                <NavLink to="/phases">
                  {({ isActive }) => (
                    <li
                      className={`flex items-center rounded-xl py-3 text-sm font-medium mb-2 px-4 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <CalendarIcon className="mr-2" width="18" height="18" />
                      Chargers
                    </li>
                  )}
                </NavLink>
                <NavLink to="/fleet">
                  {({ isActive }) => (
                    <li
                      className={`flex items-center rounded-xl py-3 text-sm font-medium mb-2 px-4 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <Pencil2Icon className="mr-2" width="18" height="18" />
                      Fleet
                    </li>
                  )}
                </NavLink>
              </ul>
            )}
          </nav>
          <div className="w-full flex flex-col justify-center p-4 absolute bottom-0 items-center">
            {(parentData && parentData.image_url && 0) && (
              <div
              style={{
                backgroundImage: `url(${parentData.image_url})`,
                backgroundSize: "cover",         // Ensures the image covers the entire container
                backgroundPosition: "left center", // Aligns the image to the left, centered vertically
                backgroundRepeat: "no-repeat"     // Prevents the image from repeating
              }}
                className="h-[200px] w-[200px] rounded-2xl gap-4  flex flex-col items-start justify-end px-5 mb-4"
              >
                {/* <div className="text-white text-[17.35px] font-sm font-['Inter']">
                  {parentData.organization_name}{" Programs and Resources"}
                </div> */}
                <a href={parentData.contact_link} target="_blank" rel="noopener noreferrer">
                  <Button className="h-10 px-4 flex gap-2 mb-8" variant="outline">
                    <div className="text-[#1b1c1b] text-[14.95px] font-medium font-['Roboto'] leading-[17.94px]">
                      Learn More
                    </div>
                    <img src="icons/sideArrow.svg"></img>
                  </Button>
                </a>
              </div>
            )}

            <div
              style={{ backgroundImage: "url(/treeimg.png)" }}
              className="h-[200px] w-[200px] rounded-2xl gap-4  flex flex-col items-start justify-center px-5 "
            >
              <div className="text-white text-[17.35px] font-sm font-['Inter']">
                Optony is working for a greener tomorrow
              </div>
              <a href="mailto:Energy@OptonyUSA.com">
                <Button className="h-10 px-4 flex gap-2" variant="outline">
                  <div className="text-[#1b1c1b] text-[14.95px] font-medium font-['Roboto'] leading-[17.94px]">
                    Contact us
                  </div>
                  <img src="icons/sideArrow.svg"></img>
                </Button>
              </a>
            </div>

            {showLinks ? (
              <div className="w-full flex justify-center mt-5">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <div className="flex w-[200px] items-center justify-between hover:bg-[#e4e6e4] rounded-lg p-2">
                      <div className="flex gap-2 items-center">
                        <Avatar className="shadow-lg">
                          <AvatarImage
                            src={cityInfo?.city_image}
                            alt="@shadcn"
                          />
                          <AvatarFallback>CN</AvatarFallback>
                        </Avatar>
                        <div className="flex flex-col items-start">
                          <div className="text-[#40423f] text-[15px] font-normal font-['Roboto'] leading-[18px]">
                            {cityInfo?.city_name}
                          </div>
                          <div className="text-[#8a8d89] text-[13px] font-normal font-['Roboto'] leading-none">
                            {username}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <button>
                          <img src="icons/userAction.svg"></img>
                        </button>
                      </div>
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-[200px]">
                    <DropdownMenuItem onClick={handleLogout}>
                      <ExitIcon className="mr-2 h-4 w-4" />
                      <span>Logout</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            ) : (
              <div className="w-full flex flex-col items-start pl-2 pt-2 justify-center ">
                <NavLink to="/login">
                  <Button className="h-10 px-4 flex gap-2" variant="outline">
                    <div className="text-[#1b1c1b] text-[14.95px] font-normal font-['Roboto'] leading-[17.94px]">
                      Logout
                    </div>
                    <ExitIcon className="" />
                  </Button>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="h-screen w-[75px] fixed bg-background_gray">
          <div className="flex items-center justify-left h-8 pl-5 mt-4">
            <button onClick={toggleNav}>
              <img src="icons/collapsedLogo.svg"></img>
            </button>
          </div>
          {/* <p data-testid="title" className="text-[#505c54] pl-4 text-2xl font-franklin tracking-wide">Pro Forma</p> */}
          <nav className="p-2 mt-4 mx-auto flex flex-col items-center">
            {showLinks && (
              <ul className="">
                <NavLink to="/">
                  {({ isActive }) => (
                    <li
                      className={`flex h-11 w-11 items-center rounded-xl py-3 text-sm font-medium mb-2 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <DesktopIcon className="m-auto" width="20" height="20" />
                    </li>
                  )}
                </NavLink>
                <NavLink to="/yearoveryear">
                  {({ isActive }) => (
                    <li
                      className={`flex h-11 w-11 items-center rounded-xl py-3 text-sm font-medium mb-2 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <BarChartIcon className="m-auto" width="20" height="20" />
                    </li>
                  )}
                </NavLink>
                <NavLink to="/phases">
                  {({ isActive }) => (
                    <li
                      className={`flex h-11 w-11 items-center rounded-xl py-3 text-sm font-medium mb-2 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <CalendarIcon className="m-auto" width="18" height="18" />
                    </li>
                  )}
                </NavLink>
                <NavLink to="/fleet">
                  {({ isActive }) => (
                    <li
                      className={`flex h-11 w-11 items-center rounded-xl py-3 text-sm font-medium mb-2 ${
                        isActive
                          ? "bg-black text-white"
                          : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
                      }`}
                    >
                      <Pencil2Icon className="m-auto" width="18" height="18" />
                    </li>
                  )}
                </NavLink>
              </ul>
            )}
          </nav>
          {showLinks ? (
            <div className="w-full flex flex-col justify-center absolute bottom-0">
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="rounded-full p-2 flex justify-center hover:bg-[#e4e6e4] w-full">
                    <Avatar className="shadow-lg">
                      <AvatarImage src={cityInfo?.city_image} alt="@shadcn" />
                      <AvatarFallback>CN</AvatarFallback>
                    </Avatar>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[200px]">
                  <DropdownMenuItem onClick={handleLogout}>
                    <ExitIcon className="mr-2 h-4 w-4" />
                    <span>Logout</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ) : (
            <div className="w-full flex flex-col items-start pl-5 justify-center absolute bottom-2">
              <NavLink to="/login">
                <Button variant="outline" className="">
                  <ExitIcon className="" />
                </Button>
              </NavLink>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Navbar;
